import React, { useEffect } from "react";
import "./Subscribe.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Row,
  Col,
  Image,
  Modal,
  ModalHeader,
  Dropdown,
  Button,
} from "react-bootstrap";
import ZoomImage from "./common/ImageHover";
import { useState } from "react";
import { useUser } from "../UserContext";
import instance from "./common/Api";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Loader from "./common/Loader";

const Subscribe = (props) => {
  const { userData, ip } = props;
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredGold, setIsHoveredGold] = useState(false);
  const [isHoveredSilver, setIsHoveredSilver] = useState(false);
  const [isHoveredBasic, setIsHoveredBasic] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [basicPlans, setBasicPlans] = useState(null);
  const [silverPlans, setSilverPlans] = useState(null);
  const [goldPlans, setGoldPlans] = useState(null);
  const [sixDaysPlans, setSixDaysPlans] = useState(null);
  const [showAgePopUp, setShowAgePopUp] = useState(false);
  const [activePlans, setActivePlans] = useState(null);

  const [showPopUp, setShowPopUp] = useState(false);

  const [selectedAge, setSelectedAge] = useState("18 years");
  const [locationData, setLocationData] = useState(null);
  const [userCity, setUserCity] = useState(null);
  const [ApplocationData, setAppLocationData] = useState(null);

  const [popUpPlan, setPopUpPlan] = useState(null);
  const [popUpOldPrice, setPopUpOldPrice] = useState(null);
  const [popUpPrice, setPopUpPrice] = useState(null);
  const [popUpSub_id, setPopUpSub_id] = useState(null);
  const [isChecked, setIsChecked] = useState(true);
  const [showPayOptions, setShowPayOptions] = useState(false);
  const [ageIdx, setAgeIdx] = useState(0);
  // const [finalPayload, setFinalPayload] = useState({});
  // const [payloadData, setPayloadData] = useState({});

  const navigate = useNavigate();

  function isMobileDevice() {
    return window.matchMedia("(max-width: 1000px)").matches;
  }
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  // const { userData } = useUser();
  const [plans, setPlans] = useState([]);
  // Function to filter plans based on title and set states
  const filterPlans = (plans) => {
    const gold = plans.content.filter(
      (plan) => plan.title.toLowerCase() === "gold"
    );
    const basic = plans.filter((plan) => plan.title.toLowerCase() === "basic");
    // Filter other plan types if needed

    setGoldPlans(gold);
    setBasicPlans(basic);
    // Set states for other plan types if needed
  };
  const formatDate = function (dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };

  const pushToLogin = () => {
    navigate("/login");
  };
  const FetchUser = () => {
    const storedUserData = localStorage.getItem("ubeetuAppUser");
    const storedSocial = localStorage.getItem("SocialLogin");
    // ocalStorage.setItem("SocialLogin", true);
    return storedUserData ? JSON.parse(storedUserData) : null;
  };
  // async function sha256(str) {
  //   const buffer = new TextEncoder().encode(str);
  //   const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  //   const hashArray = Array.from(new Uint8Array(hashBuffer));
  //   const hashHex = hashArray
  //     .map((byte) => byte.toString(16).padStart(2, "0"))
  //     .join("");
  //   return hashHex;
  // }

  // useEffect(() => {
  //   if (userData != null) {
  //     instance
  //       .post(`/getUsersState?ip=${props.ip}&id=${props.userData.id}`)
  //       .then((response) => {
  //         // setAppLocationData(response.data);
  //         console.log("/getUsersState", response.data);
  //         console.log("/getUsersState", response.data[0].cityName);
  //         console.log("/getUsersState", response.data[0].countryName);
  //         if (response.data[0].countryName === "India") {
  //           setUserCity(response.data[0].cityName);
  //         } else {
  //           setUserCity(null);
  //         }

  //         console.log("/getUsersState", response.data[1].states);
  //         setAppLocationData(response.data[1].states);
  //         //console.log(active[0], "<active");
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       })
  //       .finally(() => {
  //         setLoading(false); // Set loading to false when fetch is complete
  //       });
  //     //console.log("User ID:", props.userData.id);
  //   } else {
  //     //console.log("User ID not found");
  //     setLoading(false);
  //   }
  // }, []);

  const MakePhonePePay = async () => {
    //console.log("MakePhonePePay", payload.amount);
    //subscription_id
    // ip
    if (selectedAge === "" || selectedAge === "Select your age") {
      alert("Please select your age");
    } else {
      const payload = {
        cityName: userCity,
        age: selectedAge,
        amount: popUpPrice,
        ip: props.ip,
        id: props.userData.id,
        subscription_id: popUpSub_id,
      };

      // console.error("Error fetching payload:", payload);
      instance
        .post(
          `/user/phone_pe?amount=${payload.amount}&subscription_id=${payload.subscription_id}&id=${payload.id}&ip=${payload.ip}&cityName=${payload.cityName}&age=${payload.age}`
        )
        .then((response) => {
          window.location.href =
            response.data.data.instrumentResponse.redirectInfo.url;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // window.location.href = "/";
          setLoading(false); // Set loading to false when fetch is complete
        });
      setShowPopUp(false);
      setShowPayOptions(false);
    }
  };

  const makePayment = (data) => {
    // //console.log("makePayment", data);
    // //console.log("data.listedPrice", data.listedPrice);
    // //console.log("data.subscription_id", data.subscription_id);
    // //console.log("props.ip", props.ip);
    // //console.log("userData", props.userData.id);
    setPopUpPlan(data.title);
    setPopUpOldPrice(data.amount);
    setPopUpPrice(data.listedPrice);
    setPopUpSub_id(data.subscription_id);

    // setPayloadData(data);
    // if (selectedAge !== "Select you age") {
    //   const payload = {
    //     city: userCity,
    //     age: selectedAge,
    //     amount: data.listedPrice,
    //     ip: props.ip,
    //     id: props.userData.id,
    //     subscription_id: data.subscription_id,
    //   };
    //   // setFinalPayload(payload);

    //   // MakePhonePePay(payload);
    // } else {
    // }
    setShowPopUp(true);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the state based on checkbox's checked value
  };

  const ageArray = Array.from(
    { length: 99 - 18 + 1 },
    (_, index) => index + 18
  );

  const handleSelectChange = (event, idx) => {
    setAgeIdx(idx);
    // console.log(`${event.target.value + " years"}`);
    console.log(`${event + " years"}`);
    if (event !== null) {
      setSelectedAge(`${event + " years"}`); // Update selectedAge state with the value of the selected option
    }
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    console.log("props...:", props);
    if (userData != null) {
      instance
        .post(`/subscription_plans_web?id=${userData.id}&ip=${ip}`)
        .then((response) => {
          setSubscriptionData(response.data);
          //console.log("response.data", response.data);
          const active = response.data.active_plan;
          //console.log(active[0], "<active");
          if (response.data && response.data.active_plan.length > 0) {
            setActivePlans(response.data.active_plan[0]);
          }
          console.log(response.data.active_plan);

          const goldPlans = response.data.content.filter(
            (plan) => plan.title.toLowerCase() === "gold"
          );
          const silverPlans = response.data.content.filter(
            (plan) => plan.title.toLowerCase() === "silver"
          );
          const basicPlans = response.data.content.filter(
            (plan) => plan.title.toLowerCase() === "basic"
          );
          const sixDaysPlans = response.data.content.filter(
            (plan) => plan.title === "Three Days"
          );
          //console.log("Basic Plans:", goldPlans[0]);
          setBasicPlans(basicPlans[0]);
          setSilverPlans(silverPlans[0]);
          setGoldPlans(goldPlans[0]);
          setSixDaysPlans(sixDaysPlans[0]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when fetch is complete
        });
      //console.log("User ID:", props.userData.id);
    } else {
      //console.log("User ID not found");
      setLoading(false);
    }
    if (userData != null) {
      instance
        .post(`/getUsersState?ip=${props.ip}&id=${props.userData.id}`)
        .then((response) => {
          // setAppLocationData(response.data);
          console.log("/getUsersState", response.data);
          console.log("/getUsersState", response.data[0].cityName);
          console.log("/getUsersState", response.data[0].countryName);
          if (response.data[0].countryName === "India") {
            setUserCity(response.data[0].cityName);
          } else {
            setUserCity(null);
          }

          console.log("/getUsersState", response.data[1].states);
          setAppLocationData(response.data[1].states);
          //console.log(active[0], "<active");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when fetch is complete
        });
      //console.log("User ID:", props.userData.id);
    } else {
      //console.log("User ID not found");
      setLoading(false);
    }
    // filterPlans(subscriptionData);
    //console.log("subscriptionData", props.userData);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={
        props.isMobile
          ? {
              width: "100%",
              minHeight: "55rem",
              // position: "relative",
              backgroundImage: `url(./main-bg.jpg)`,
              backgroundSize: "auto",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "repeat-y",
              backgroundBlendMode: "overlay",
            }
          : {
              backgroundImage: `url(./main-bg-landscap.jpg)`,
              backgroundSize: "auto",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "repeat-y",
              backgroundBlendMode: "overlay",
            }
      }
    >
      <Modal
        show={showPopUp}
        onHide={() => {
          setShowPopUp(false);
          setShowPayOptions(false);
        }}
        style={{ marginTop: "1rem" }}
      >
        <ModalHeader
          style={{
            padding: "0",
            backgroundColor: "#35332f",
            borderBottom: "none",
          }}
        >
          <h6
            style={{
              marginTop: "20px",
              marginLeft: "10px",
              color: "white",
            }}
          >
            Select payment options for subscribing
          </h6>{" "}
        </ModalHeader>
        <Modal.Body
          style={{
            backgroundImage: `url(./subscribe-Payment-age.png)`,
            backgroundSize: "cover",
            color: "white",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            // borderRadius: "10px",
            // height: "15rem", // Adjust as needed
            backgroundColor: "black",
          }}
        >
          <div
            style={{
              // maxHeight: "300px", // Set your desired fixed height here
              // overflowY: "scroll",
              width: "100%", // Ensure full width
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
              }}
            >
              <p style={{ display: "inline", color: "#007cc2" }}>
                {popUpPlan && popUpPlan}
              </p>
              <p
                style={{
                  display: "inline",
                  marginLeft: "auto",
                  textDecoration: "line-through",
                  color: "#008dc4",
                }}
              >
                ₹ {popUpOldPrice && popUpOldPrice}
              </p>

              <p style={{ display: "inline", color: "#007cc2" }}>
                ₹ {popUpPrice && popUpPrice}
              </p>
            </div>
            {showPayOptions ? (
              <>
                {/* <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                    color: "white",
                    width: "100%",
                    backgroundColor: "#008dc4",
                  }}
                  // onClick={MakePhonePePay}
                >
                  Debit Card | Credit Card | Net Banking
                </Button> */}
                {/* <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                    color: "white",
                    width: "100%",
                    backgroundColor: "#008dc4",
                  }}
                  // onClick={MakePhonePePay}
                >
                  Google Pay | UPI Payment
                </Button> */}
                <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                    color: "white",
                    width: "100%",
                    backgroundColor: "#008dc4",
                  }}
                  onClick={MakePhonePePay}
                >
                  PhonePe | UPI Payment
                </Button>
              </>
            ) : (
              <>
                {userCity && (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      style={{ color: "#007cc2" }}
                    >
                      {userCity && userCity}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        height: "10rem",
                        overflowY: "auto",
                        backgroundColor: "#008dc4",
                      }}
                    >
                      {ApplocationData &&
                        ApplocationData.map((item, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => setUserCity(item.name)}
                          >
                            {item.name}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                <p
                  style={{
                    marginRight: "auto",
                    fontWeight: "700",
                    color: "white",
                  }}
                >
                  Select your age:{" "}
                </p>
                <div
                  style={{
                    maxHeight: "8rem", // Set your desired fixed height here
                    overflowY: "scroll",
                    backgroundColor: "#008dc4",
                    borderRadius: "10px",
                    padding: "10px",
                    width: "100%",
                  }}
                >
                  {ageArray &&
                    ageArray.map((item, idx) => (
                      <p
                        key={idx}
                        style={{
                          color: "white",
                          cursor: "pointer",
                          backgroundColor: ageIdx === idx ? "#007cc2" : "",
                          borderRadius: "3px",
                        }}
                        onClick={() => handleSelectChange(item, idx)}
                      >
                        {item} Years
                      </p>
                    ))}
                </div>
                {/* <Form.Select
                  aria-label="Default select example"
                  onChange={handleSelectChange}
                  style={{
                    marginTop: "1rem",
                    // borderColor: selectedAge === "Select you age" ? "red" : "white",
                  }}
                >
                  <option style={{ height: "10rem", overflowY: "auto" }}>
                    Select you age
                  </option>
                  {ageArray &&
                    ageArray.map((item, index) => (
                      <option value={item} key={index}>
                        <option value={item} key={index}>
                          {item} Years
                        </option>
                      </option>
                    ))}
                </Form.Select> */}
                <div>
                  <p style={{ fontSize: "12px", marginTop: "10px" }}>
                    <input
                      type="checkbox"
                      style={{
                        marginRight: "5px",
                        color: isChecked ? "#007cc2" : "black",
                      }}
                      checked={isChecked}
                      onChange={handleCheckboxChange} // Call handleCheckboxChange function on checkbox change
                    />
                    <span style={{ color: "black" }}>
                      I have Read and Accepted the{" "}
                    </span>
                    <span style={{ color: "#007cc2" }}>
                      Terms and condition
                    </span>
                    <span style={{ color: "black" }}> and </span>
                    <span style={{ color: "#007cc2" }}>Privacy Policy</span>
                    <span style={{ color: "black" }}> and </span>
                    <span style={{ color: "#007cc2" }}>Refund Policy</span>
                    <span style={{ color: "black" }}>.</span>
                  </p>
                </div>
                <Button
                  variant="secondary"
                  disabled={!isChecked || selectedAge === null}
                  style={{
                    marginTop: "20px",
                    color: "white",
                    backgroundColor: "#008dc4",
                  }}
                  onClick={() => setShowPayOptions(true)}
                >
                  Continue
                </Button>
              </>
            )}
          </div>
          {/* {userCity && (
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                style={{ color: "#007cc2" }}
              >
                {userCity && userCity}
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  height: "10rem",
                  overflowY: "auto",
                  backgroundColor: "#008dc4",
                }}
              >
                {ApplocationData &&
                  ApplocationData.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => setUserCity(item.name)}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          )}

          <Form.Select
            aria-label="Default select example"
            onChange={handleSelectChange}
            style={{
              marginTop: "1rem",
              // borderColor: selectedAge === "Select you age" ? "red" : "white",
            }}
          >
            <option style={{ height: "10rem", overflowY: "auto" }}>
              Select you age
            </option>
            {ageArray &&
              ageArray.map((item, index) => (
                <option value={item} key={index}>
                  <option value={item} key={index}>
                    {item} Years
                  </option>
                </option>
              ))}
          </Form.Select>
          <div>
            <p style={{ fontSize: "12px", marginTop: "10px" }}>
              <input
                type="checkbox"
                style={{
                  marginRight: "5px",
                  color: isChecked ? "#007cc2" : "black",
                }}
                checked={isChecked}
                onChange={handleCheckboxChange} // Call handleCheckboxChange function on checkbox change
              />
              <span style={{ color: "black" }}>
                I have Read and Accepted the{" "}
              </span>
              <span style={{ color: "#007cc2" }}>Terms and condition</span>
              <span style={{ color: "black" }}> and </span>
              <span style={{ color: "#007cc2" }}>Privacy Policy</span>
              <span style={{ color: "black" }}> and </span>
              <span style={{ color: "#007cc2" }}>Refund Policy</span>
              <span style={{ color: "black" }}>.</span>
            </p>
          </div>

          <Button
            variant="secondary"
            disabled={!isChecked}
            style={{
              marginTop: "20px",
              color: "white",
              backgroundColor: "#008dc4",
            }}
            onClick={MakePhonePePay}
          >
            Continue
          </Button> */}
        </Modal.Body>
      </Modal>
      {props && props.userData != null ? (
        <>
          {activePlans != null ? (
            <>
              <Container style={{ padding: "20px", minHeight: "55rem" }}>
                {loading && (
                  <div className="loading-overlay">
                    <Loader isLoading={loading} />
                  </div>
                )}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} className="text-center text-white">
                      <h1 style={{ color: "#007cc2" }}>
                        Your Subscribed Package
                      </h1>
                    </Col>
                  </Row>
                </Container>
                <Row style={{ padding: "20px" }}>
                  <Col xs={12} md={2}></Col>
                  <Col
                    md={8}
                    className={`d-flex flex-column align-items-center mb-4 ${
                      isHoveredGold ? "zoomed-card" : ""
                    }`}
                  >
                    <Image
                      src="./plans_bg.svg"
                      style={{
                        paddingBottom: "20px",
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                    <h1 className="month">
                      12
                      <br />
                      Months
                    </h1>
                    <h4 className="rupee">₹</h4>
                    <h1 className="offerPrice">
                      {activePlans.subscription_amount}
                    </h1>
                    <h1 className="planName">{activePlans.title}</h1>
                  </Col>
                  <Col md={2}></Col>
                </Row>
                <Row style={{ padding: isMobile ? "0px" : "20px" }}>
                  <Col md={isMobile ? 1 : 2}></Col>
                  <Col
                    md={
                      props.userData.mobile === null
                        ? isMobile
                          ? 3
                          : 4
                        : isMobile
                        ? 2
                        : 3
                    }
                  >
                    <div
                      style={{
                        // backgroundColor: "#007cc2",
                        // color: "white",
                        // textAlign: "center",
                        // padding: "10px",
                        // margin: "5px", // Added margin for spacing
                        // borderRadius: "9px",
                        // width: "auto",

                        display: "inline-block",
                        padding: "10px",
                        margin: "5px",
                        backgroundColor: "#007cc2",
                        borderRadius: "9px",
                        textAlign: "center",
                        color: "white",
                        width:
                          props.userData.mobile === null
                            ? isMobile
                              ? "-webkit-fill-available"
                              : "330px"
                            : isMobile
                            ? "-webkit-fill-available"
                            : "280px",
                      }}
                    >
                      <h6>User</h6>
                      <h4
                        style={{
                          textWrap:
                            props.userData.mobile != null &&
                            props.userData.mobile != ""
                              ? "true"
                              : "false",
                          wordWrap: "break-word",
                        }}
                      >
                        {props.userData.mobile != null &&
                        props.userData.mobile != ""
                          ? props.userData.mobile
                          : props.userData.email}
                      </h4>
                    </div>
                  </Col>
                  <Col md={isMobile ? 3 : 2}>
                    <div
                      style={{
                        backgroundColor: "#007cc2",
                        color: "white",
                        textAlign: "center",
                        padding: "10px",
                        margin: "5px", // Added margin for spacing
                        borderRadius: "9px",
                      }}
                    >
                      <h6>Expiry Date</h6>
                      {/* <h4>{formatDate(activePlans.expiry_date)}</h4> */}
                      <h4>{activePlans.expiry_date}</h4>
                    </div>
                  </Col>
                  <Col md={isMobile ? 3 : 2}>
                    <div
                      style={{
                        backgroundColor: "#007cc2",
                        color: "white",
                        textAlign: "center",
                        padding: "10px",
                        margin: "5px",
                        borderRadius: "9px",
                        width: "auto",
                      }}
                    >
                      <h6>Transaction Id</h6>
                      <h4>XXXX{activePlans.transaction_id}</h4>
                    </div>
                  </Col>
                  <Col md={isMobile ? 1 : 2}></Col>
                </Row>
              </Container>
            </>
          ) : (
            <>
              <Container style={{ padding: "20px", minHeight: "55rem" }}>
                {loading && (
                  <div className="loading-overlay">
                    <Spinner animation="border" />
                  </div>
                )}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} className="text-center text-white">
                      <h1 style={{ color: "#007cc2" }}>
                        Subscription Packages
                      </h1>
                    </Col>
                  </Row>
                </Container>
                <Row style={{ padding: "20px" }}>
                  {goldPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredGold ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredGold(true)}
                      onMouseLeave={() => setIsHoveredGold(false)}
                      onClick={() => makePayment(goldPlans)}
                    >
                      <Image
                        src="./plans_bg.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        12
                        <br />
                        Months
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{goldPlans.listedPrice}</h1>
                      <h1 className="planName">{goldPlans.title}</h1>
                      <h4 className="oldPrice">₹ {goldPlans.amount}</h4>
                    </Col>
                  )}
                  {silverPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredSilver ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredSilver(true)}
                      onMouseLeave={() => setIsHoveredSilver(false)}
                      onClick={() => makePayment(silverPlans)}
                    >
                      <Image
                        src="./plans_bg.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        3<br />
                        Months
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{silverPlans.listedPrice}</h1>
                      <h1 className="planName">{silverPlans.title}</h1>
                      <h4 className="oldPrice">₹ {silverPlans.amount}</h4>
                    </Col>
                  )}
                </Row>
                <Row>
                  {basicPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredBasic ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredBasic(true)}
                      onMouseLeave={() => setIsHoveredBasic(false)}
                      onClick={() => makePayment(basicPlans)}
                    >
                      <Image
                        src="./plans_bg.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        1<br />
                        Month
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{basicPlans.listedPrice}</h1>
                      <h1 className="planName">{basicPlans.title}</h1>
                      <h4 className="oldPrice">₹ {basicPlans.amount}</h4>
                    </Col>
                  )}
                  {sixDaysPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHovered ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onClick={() => makePayment(sixDaysPlans)}
                    >
                      <Image
                        src="./plans_bg.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        3<br />
                        Days
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{sixDaysPlans.listedPrice}</h1>
                      <h1 className="planName">{sixDaysPlans.title}</h1>
                      <h4 className="oldPrice">₹ {sixDaysPlans.amount}</h4>
                    </Col>
                  )}
                </Row>
              </Container>
            </>
          )}
        </>
      ) : (
        <Container style={{ padding: "20px", minHeight: "55rem" }}>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} className="text-center text-white">
                <h1 style={{ color: "#007cc2" }}>Subscription Packages</h1>
              </Col>
            </Row>
          </Container>
          <Row style={{ padding: "20px" }}>
            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHoveredGold ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHoveredGold(true)}
              onMouseLeave={() => setIsHoveredGold(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./plans_bg.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                12
                <br />
                Months
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">99</h1>
              <h1 className="planName">GOLD</h1>
              <h4 className="oldPrice">₹ 300</h4>
            </Col>

            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHoveredSilver ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHoveredSilver(true)}
              onMouseLeave={() => setIsHoveredSilver(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./plans_bg.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                3<br />
                Months
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">72</h1>
              <h1 className="planName">SILVER</h1>
              <h4 className="oldPrice">₹ 200</h4>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHoveredBasic ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHoveredBasic(true)}
              onMouseLeave={() => setIsHoveredBasic(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./plans_bg.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                1<br />
                Month
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">45</h1>
              <h1 className="planName">BASIC</h1>
              <h4 className="oldPrice">₹ 125</h4>
            </Col>

            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHovered ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./plans_bg.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                3<br />
                Days
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">15</h1>
              <h1 className="planName">SIX DAYS</h1>
              <h4 className="oldPrice">₹ 87</h4>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default Subscribe;

// <div className="container">
//   <div className="row row-grid justify-content-center" style={{ minHeight: '690px' }}>
//     <div className="col-lg-12 packagesBox">
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-md-12 pageTtileBox">
//             <h1>Subscription Packages</h1>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-0">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 12 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> GOLD </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 300</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 99
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-1">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 3 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> SILVER </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 200</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 72
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* Add more package columns similarly */}
//         </div>
//         <div className="row">
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-0">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 12 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> GOLD </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 300</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 99
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-1">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 3 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> SILVER </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 200</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 72
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* Add more package columns similarly */}
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
